







































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Breadcrumb from '@/components/Breadcrumb.vue'
import router from '@/router'
import FormObj from '@/interfaces/FormObj'

@Component({
  components: {
    Breadcrumb
  }
})
export default class Contact extends Vue {
  ftype = ''
  fbooklet = false
  fexplain = false
  fname = ''
  fcompany = ''
  flisting = ''
  fdepartment = ''
  femail = ''
  ftel = ''
  ftext = ''
  isSubmit = false
  canSubmit = false
  msg = {
    caution: '',
    ftype: '',
    fname: '',
    fcompany: '',
    flisting: '',
    fdepartment: '',
    femail: '',
    ftel: '',
    ftext: ''
  }
  typeList = [
    {
      value: 'business-development',
      text: 'サービスや協業に関するお問い合わせ（ポイント運用やココカブ）'
    },
    {
      value: 'pr',
      text: '取材や登壇に関するお問い合わせ'
    },
    {
      value: 'hr',
      text: '採用に関するお問い合わせ'
    },
    {
      value: 'info',
      text: 'その他、当社に関するお問い合わせ'
    }
  ]
  listingList = [
    {
      value: 'on',
      text: '上場'
    },
    {
      value: 'off',
      text: '非上場'
    }
  ]
  MAX_TEXT_LENGTH = 1000
  MAX_INPUT_LENGTH = 30
  MAX_EMAIL_LENGTH = 255

  checkForm(): void {
    this.canSubmit = true
    if (!this.checkType()) this.canSubmit = false
    if (!this.checkName()) this.canSubmit = false
    if (!this.checkCompany()) this.canSubmit = false
    if (!this.checkListing()) this.canSubmit = false
    if (!this.checkDepartment()) this.canSubmit = false
    if (!this.checkEmail()) this.canSubmit = false
    if (!this.checkTel()) this.canSubmit = false
    if (!this.checkText()) this.canSubmit = false
  }

  checkType(): boolean {
    this.msg.ftype = ''
    if (this.ftype == '') {
      this.msg.ftype = '未選択です。'
      return false
    }
    return true
  }

  checkName(): boolean {
    this.msg.fname = ''
    if (this.fname == '') {
      this.msg.fname = '未入力です。'
      return false
    }
    if (this.fname.length > this.MAX_INPUT_LENGTH) {
      this.msg.fname = `${this.MAX_INPUT_LENGTH}文字以内で入力してください。`
      return false
    }
    return true
  }

  checkCompany(): boolean {
    this.msg.fcompany = ''
    if (this.fcompany == '') {
      this.msg.fcompany = '未入力です。'
      return false
    }
    if (this.fcompany.length > this.MAX_INPUT_LENGTH) {
      this.msg.fcompany = `${this.MAX_INPUT_LENGTH}文字以内で入力してください。`
      return false
    }
    return true
  }

  checkListing(): boolean {
    this.msg.flisting = ''
    if (this.flisting == '') {
      this.msg.flisting = '未選択です。'
      return false
    }
    return true
  }

  checkDepartment(): boolean {
    this.msg.fdepartment = ''
    if (this.fdepartment == '') {
      this.msg.fdepartment = '未入力です。'
      return false
    }
    if (this.fdepartment.length > this.MAX_INPUT_LENGTH) {
      this.msg.fdepartment = `${this.MAX_INPUT_LENGTH}文字以内で入力してください。`
      return false
    }
    return true
  }

  checkEmail(): boolean {
    this.msg.femail = ''
    if (this.femail == '') {
      this.msg.femail = '未入力です。'
      return false
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.femail)) {
      this.msg.femail = '正しく入力してください。'
      return false
    }
    if (this.femail.length > this.MAX_EMAIL_LENGTH) {
      this.msg.femail = `${this.MAX_EMAIL_LENGTH}文字以内で入力してください。`
      return false
    }
    return true
  }

  checkTel(): boolean {
    this.msg.ftel = ''
    if (this.ftel == '') {
      this.msg.ftel = '未入力です。'
      return false
    }
    if (!/^0\d{9,10}$/.test(this.ftel)) {
      this.msg.ftel = '数字のみで、10桁または11桁で入力してください。'
      return false
    }
    return true
  }

  checkText(): boolean {
    this.msg.ftext = ''
    if (this.ftext == '') {
      this.msg.ftext = '未入力です。'
      return false
    }
    if (this.ftext.length > this.MAX_TEXT_LENGTH) {
      this.msg.ftext = `${this.MAX_TEXT_LENGTH}文字以内で入力してください。`
      return false
    }
    return true
  }

  submitForm(e: Event): void {
    e.preventDefault()
    this.msg.caution = ''
    this.checkForm()

    if (!this.isSubmit && this.canSubmit) {
      this.isSubmit = true

      let objects: FormObj = {
        type: this.getTextFromValue(this.ftype, this.typeList),
        booklet: this.fbooklet ? '希望あり' : '希望なし',
        explain: this.fexplain ? '希望あり' : '希望なし',
        name: this.fname,
        company: this.fcompany,
        listing: this.getTextFromValue(this.flisting, this.listingList),
        depart: this.fdepartment,
        mail: this.femail,
        tel: this.ftel,
        message: this.ftext.replace(/\r?\n/g, '<br>'),
        corporate_alias: this.ftype
      }
      console.log(objects)

      const reqData = JSON.stringify(objects)
      this.postForm(reqData)
    } else if (!this.canSubmit) {
      this.msg.caution = '入力内容に誤りがあります。'
      const ele = document.getElementById('mailform')
      if (ele) {
        ele.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  getTextFromValue(
    value: string,
    list: Array<{ value: string; text: string }>
  ): string {
    const obj = list.find((obj) => obj.value == value)
    if (obj) {
      return obj.text
    } else {
      return ''
    }
  }

  async postForm(data: string): Promise<void> {
    const API_HOST = [
      'stockpoint.jp',
      'www.stockpoint.jp',
      'stockpoint.co.jp',
      'www.stockpoint.co.jp'
    ].includes(location.hostname)
      ? 'api.stockpoint.co.jp'
      : 'api.dev.stockpoint.co.jp'
    const url = `https://${API_HOST}/Send_ContactMail`
    await axios
      .post(url, data)
      .then(function (res) {
        console.log(res)
        router.push({ name: 'Thanks' })
      })
      .catch(function (e) {
        console.log(e)
        window.alert(
          'システムエラーが発生しました。お手数ですがしばらくたってから再度送信してください。'
        )
        location.reload()
      })
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pressList"},_vm._l((_vm.pressList),function(press,index){return _c('a',{key:press.yymmdd_nn,class:[
        'card',
        _vm.isNewPress(press.yymmdd_nn) ? 'pressList_card-new' : 'pressList_card',
        press.url == null ? 'noLink' : ''
      ],attrs:{"href":press.url == '' ? ("/press/" + (press.yymmdd_nn)) : press.url,"target":press.isNewTab ? '_blank' : ''}},[_c('div',{staticClass:"card_thumbnail thumbnail_wrapper"},[_c('img',{staticClass:"thumbnail",attrs:{"alt":"Thumbnail","width":"750","height":"350","src":press.thumbnailImg != ''
              ? press.thumbnailImg
              : '/img/thumbnail.svg'}})]),_c('div',{staticClass:"card_detail"},[_c('div',{class:[
            'card_category',
            ("card_" + (_vm.getCategoryClass(press.category)))
          ]},[_vm._v(" "+_vm._s(press.category)+" ")]),_c('div',{staticClass:"card_date date"},[_c('span',{staticClass:"date_yy"},[_vm._v("20"+_vm._s(press.yymmdd_nn.slice(0, 2))+_vm._s(index == 0 ? '.' : '.'))]),_c('span',{staticClass:"date_mm"},[_vm._v(_vm._s(press.yymmdd_nn.slice(2, 4).replace(/^0+/, ''))+_vm._s(index == 0 ? '.' : '.'))]),_c('span',{staticClass:"date_dd"},[_vm._v(_vm._s(press.yymmdd_nn.slice(4, 6).replace(/^0+/, ''))+_vm._s(index == 0 ? '.' : '.'))]),_c('span',{staticClass:"date_day"},[_vm._v(_vm._s(_vm.getDayOfWeek(press.yymmdd_nn.slice(0, 6))))])]),_c('div',{staticClass:"card_title"},[_vm._v(" "+_vm._s(press.title)+" ")]),(press.otherHTMLText)?_c('div',{staticClass:"otherHTMLText",domProps:{"innerHTML":_vm._s(press.otherHTMLText)}}):_vm._e()])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./CocokabuFooter.vue?vue&type=template&id=2cc1c09a&scoped=true&"
var script = {}
import style0 from "./CocokabuFooter.vue?vue&type=style&index=0&id=2cc1c09a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc1c09a",
  null
  
)

export default component.exports















































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'

@Component({
  components: {
    Breadcrumb
  }
})
export default class Privacy extends Vue {}






import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Top extends Vue {
  beforeCreate(): void {
    location.href = '/stockpoint/index.html'
  }
}

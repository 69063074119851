















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import PressObj from '@/interfaces/PressObj'

@Component
export default class Breadcrumb extends Vue {
  @Prop() detailPress: PressObj | undefined

  breadcrumbList: { name: string; text: string }[] = []

  mounted(): void {
    const pathList: string[] = this.$route.path.split('/')
    for (let i = 1; i < pathList.length; i++) {
      if (pathList[i] != '') {
        pathList[i] = `${pathList[i - 1]}/${pathList[i]}`
      }
    }
    pathList[0] = '/'

    pathList.forEach((path) => {
      if (path != '') {
        if (this.$router.options.routes != null) {
          const route = this.$router.options.routes.find(
            (route) => route.path == path
          )
          if (route != null) {
            this.breadcrumbList.push({
              name: route.name == null ? '' : route.name,
              text: route.meta.title
            })
          } else {
            this.breadcrumbList.push({ name: '', text: '' })
          }
        } else {
          this.breadcrumbList.push({ name: '', text: '' })
        }
      }
    })
  }

  isNow(name: string): boolean {
    return this.$route.name == name
  }

  onClickToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}












































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import NewsList from '@/components/NewsList.vue'
import YearRadio from '@/components/YearRadio.vue'
import PressObj from '@/interfaces/PressObj'
import { getAllPress, getYearListFromPressList } from '@/lib/listModules'
import { getYearStringFromYYMMDD_NN } from '@/lib/dateModules'

@Component({
  components: {
    Breadcrumb,
    NewsList,
    YearRadio
  }
})
export default class Press extends Vue {
  newsList: Array<PressObj> = []
  filteredList: Array<PressObj> = []
  yearList: Array<string> = []

  async mounted(): Promise<void> {
    this.newsList = await getAllPress()
    this.filteredList = this.newsList
    this.yearList = getYearListFromPressList(this.newsList)
  }

  onChangeCheck(checkedYear: string): void {
    if (checkedYear !== '') {
      this.filteredList = this.newsList.filter(
        (press: PressObj) =>
          checkedYear == getYearStringFromYYMMDD_NN(press.yymmdd_nn)
      )
    } else {
      this.filteredList = this.newsList
    }
  }
}

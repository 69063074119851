

















































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import PressList from '@/components/PressList.vue'
import PressObj from '@/interfaces/PressObj'
import { getPressList } from '@/lib/listModules'

@Component({
  components: {
    Breadcrumb,
    PressList
  }
})
export default class Press extends Vue {
  pressList: Array<PressObj> = []

  async mounted(): Promise<void> {
    this.pressList = await getPressList()
  }
}

export function getCategoryClass(category: string): string {
  if (category == 'メディア掲載') {
    return 'category-media'
  } else if (category == 'お知らせ') {
    return 'category-notice'
  } else if (category == 'キャンペーン') {
    return 'category-campaign'
  } else if (category == 'ニュース') {
    return 'category-news'
  } else if (category == '重要なお知らせ') {
    return 'category-important'
  } else if (category == 'レポート') {
    return 'category-report'
  } else if (category == '年頭所感') {
    return 'category-newyear'
  } else {
    return ''
  }
}

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueGtm from '@gtm-support/vue2-gtm'
import Top from '@/views/Top.vue'
import Old from '@/views/Old.vue'
import Company from '@/views/company/Company.vue'
import Vision from '@/views/company/Vision.vue'
import Member from '@/views/company/Member.vue'
import Press from '@/views/press/Press.vue'
import OldPress from '@/views/press/OldPress.vue'
import PressDetail from '@/views/press/Detail.vue'
import Kit from '@/views/press/Kit.vue'
import Services from '@/views/services/Services.vue'
import Services2 from '@/views/services/Services2.vue'
import CocokabuLP from '@/views/cocokabu/LP.vue'
import Others from '@/views/others/Others.vue'
import Recruit from '@/views/others/Recruit.vue'
import Media from '@/views/others/Media.vue'
import Contact from '@/views/contact/Contact.vue'
import Privacy from '@/views/privacy/Privacy.vue'
import Thanks from '@/views/contact/Thanks.vue'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

if (
  location.hostname == 'stockpoint.co.jp' ||
  location.hostname == 'www.stockpoint.co.jp' ||
  location.hostname == 'stockpoint.jp' ||
  location.hostname == 'www.stockpoint.jp'
) {
  Vue.use(VueGtm, {
    id: 'GTM-56HJXQC'
  })
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Top',
    meta: {
      title: 'Top',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Top
  },
  {
    path: '/index.html',
    name: 'Old',
    component: Old
  },
  {
    path: '/company',
    name: 'Company',
    meta: {
      title: 'STOCK POINTについて',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Company
  },
  {
    path: '/company/index.html',
    redirect: { name: 'Company' }
  },
  {
    path: '/vision',
    name: 'Vision',
    meta: {
      title: '私たちの目指す未来',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Vision
  },
  {
    path: '/company/member',
    name: 'Member',
    meta: {
      title: 'マネジメントメンバー紹介',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Member
  },
  {
    path: '/press',
    name: 'Press',
    meta: {
      title: 'お知らせ／プレスリリース',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Press
  },
  {
    path: '/oldpress',
    name: 'OldPress',
    meta: {
      title: '全てのお知らせ一覧',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: OldPress
  },
  {
    path: '/press/:yymmdd_nn',
    name: 'PressDetail',
    meta: {
      title: 'お知らせ',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: PressDetail
  },
  {
    path: '/presskit',
    name: 'Kit',
    meta: {
      title: 'プレスキット',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Kit
  },
  {
    path: '/services',
    name: 'Services',
    meta: {
      title: 'サービス',
      desc: 'StockPointは、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ハウスポイントと自社株が連動。ポイントを使ってビジネスを加速。'
    },
    component: Services
  },
  {
    path: '/servicestoc',
    name: 'Services2',
    meta: {
      title: 'サービス',
      desc: 'StockPointは、個別企業の株価に連動してポイントが増減するポイント運用サービス。StockPoint、StockPoint for CONNECT、STOCKPOINT for MUFG、StockPoint for CHEER証券、StockPoint Walletを使ってポイントで簡単に疑似投資を体験。'
    },
    component: Services2
  },
  {
    path: '/cocokabu',
    name: 'Cocokabu',
    meta: {
      title: 'ココカブ',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: CocokabuLP
  },
  {
    path: '/others',
    name: 'Others',
    meta: {
      title: 'STOCK POINTをもっと知る',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Others
  },
  {
    path: '/others/recruit',
    name: 'Recruit',
    meta: {
      title: '採用情報',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Recruit
  },
  {
    path: '/others/media',
    name: 'Media',
    meta: {
      title: 'STOCK POINT公式アカウント',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Media
  },
  {
    path: '/mailform.html',
    name: 'Contact',
    meta: {
      title: 'お問い合わせ',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Contact
  },
  {
    path: '/thanks.html',
    name: 'Thanks',
    meta: {
      title: 'お問い合わせ完了',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Thanks
  },
  {
    path: '/privacy.html',
    name: 'Privacy',
    meta: {
      title: 'プライバシーポリシー',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: Privacy
  },
  {
    path: '/*',
    name: 'NotFound',
    meta: {
      title: '404 Error',
      desc: 'StockPoint（ストックポイント）は、株価、投信、暗号資産、カーボンクレジット等、あらゆる市場資産価格と連動し、毎日ポイントが変動する世界初のポイントサービス。ポイント運用アプリで擬似投資体験。「株価連動型ポイント」は日米特許取得済み。'
    },
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: 'smooth' })
        }, 500)
      })
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router





















































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CocokabuHeader extends Vue {
  isDispHum = false
  toggleHum(): void {
    this.isDispHum = !this.isDispHum
  }
  closeHum(): void {
    this.isDispHum = false
  }
}

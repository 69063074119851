import axios from 'axios'
import PressObj from '@/interfaces/PressObj'
import UrgentObj from '@/interfaces/UrgentObj'
import {
  getServerDateString,
  getDateFromYYMMDD_NN,
  getYearStringFromYYMMDD_NN
} from '@/lib/dateModules'

export async function getPressList(): Promise<Array<PressObj>> {
  const url = '/list/press.json'
  const res: Array<PressObj> = await axios
    .get(url)
    .then(function (res) {
      return res.data
    })
    .catch(function (e) {
      console.log(e)
      return false
    })

  if (!res) {
    return []
  } else {
    const nowDate = new Date()
    const borderDate = new Date(`${nowDate.getFullYear() - 1}/01/01`)
    const arr: Array<PressObj> = res.filter(
      (press: PressObj) =>
        (!press.releaseDate || press.releaseDate <= getServerDateString()) &&
        borderDate <= getDateFromYYMMDD_NN(press.yymmdd_nn)
    )
    if (arr.length) {
      arr.sort((a: PressObj, b: PressObj) => b.orderNum - a.orderNum)
    }
    return arr
  }
}

export async function getAllPress(): Promise<Array<PressObj>> {
  const url = '/list/press.json'
  const res: Array<PressObj> = await axios
    .get(url)
    .then(function (res) {
      return res.data
    })
    .catch(function (e) {
      console.log(e)
      return false
    })

  if (!res) {
    return []
  } else {
    const arr: Array<PressObj> = res.filter(
      (press: PressObj) =>
        !press.releaseDate || press.releaseDate <= getServerDateString()
    )
    return arr
  }
}

export async function getTopicList(): Promise<Array<PressObj>> {
  const url = '/list/press.json'
  const MAX_TOPIC_LIST = 3
  const res: Array<PressObj> = await axios
    .get(url)
    .then(function (res) {
      return res.data
    })
    .catch(function (e) {
      console.log(e)
      return false
    })

  if (!res) {
    return []
  } else {
    const arr: Array<PressObj> = res.filter(
      (press: PressObj) =>
        !press.releaseDate || press.releaseDate <= getServerDateString()
    )
    if (arr.length) {
      arr.sort((a: PressObj, b: PressObj) => b.orderNum - a.orderNum)
    }
    return arr.slice(0, MAX_TOPIC_LIST)
  }
}

export async function getUrgentList(): Promise<Array<UrgentObj>> {
  const url = '/list/urgent.json'

  const res: Array<UrgentObj> = await axios
    .get(url)
    .then(function (res) {
      return res.data
    })
    .catch(function (e) {
      console.log(e)
      return false
    })

  if (!res) {
    return []
  } else {
    return res
  }
}

export function getYearListFromPressList(
  pressList: Array<PressObj>
): Array<string> {
  const yearList: Array<string> = []
  pressList.forEach((press) => {
    const yyyy = getYearStringFromYYMMDD_NN(press.yymmdd_nn)
    const isExist = yearList.some((year) => year == yyyy)
    if (!isExist) {
      yearList.push(yyyy)
    }
  })

  return yearList
}





























































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import PressObj from '@/interfaces/PressObj'
import {
  getDayOfWeek,
  getDateString,
  getDateFromYYMMDD_NN,
  getServerDateString
} from '@/lib/dateModules'
import { getCategoryClass } from '@/lib/categoryModules'

@Component
export default class PressList extends Vue {
  @Prop() pressList?: Array<PressObj>

  getCategoryClass(category: string): string {
    return getCategoryClass(category)
  }

  getDayOfWeek(yymmdd: string): string {
    return getDayOfWeek(yymmdd)
  }

  isNewPress(yymmdd_nn: string): boolean {
    // 日付ベースで、1週間経過した記事はNEWをつけない
    const nowDate = new Date(getServerDateString())
    const borderDate = new Date(nowDate)
    borderDate.setDate(nowDate.getDate() - 7)
    borderDate.setHours(0, 0, 0, 0)
    return borderDate < getDateFromYYMMDD_NN(yymmdd_nn)
  }
}

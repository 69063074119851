






















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'

const SLIDE_INTERVAL_MSEC = 5000
@Component
export default class ServicesSlider extends Vue {
  nowSlide = 0
  slideLength = 7
  timer = 0

  mounted(): void {
    this.setTimer()
  }

  setTimer(): void {
    this.timer = setInterval(this.slideNext, SLIDE_INTERVAL_MSEC)
  }

  slideNext(): void {
    if (this.nowSlide + 1 >= this.slideLength) {
      this.nowSlide = 0
    } else {
      this.nowSlide += 1
    }
  }

  slideTo(n: number): void {
    clearInterval(this.timer)
    this.nowSlide = n
    this.setTimer()
  }
}



















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import MemberObj from '@/interfaces/MemberObj'

@Component({
  components: {
    Breadcrumb
  }
})
export default class Member extends Vue {
  memberList: Array<MemberObj> = [
    {
      id: 1,
      name: '土屋 清美',
      imgUrl: '/img/img_member_tsuchiya.svg',
      post: 'chairman',
      article:
        '2006年　株式会社Sound-F設立<br>2016年　STOCK POINT株式会社を設立<br><br>「金融」が、もっと身近で、生活に直結したサービスであること、そして、私たちひとりひとりの生活のあり方が、将来のより良い社会作りにつながると信じて、STOCK POINTを立ち上げ、現在に至る。<br><br>主にはポイント運用サービスのマーケティング企画と運用、会員とのコミュニケーションや様々なキャンペーン企画とその推進を担当する。<br>起業当初の「いつのまにか株主」「社会の一員としての、ひとりひとりのオーナーシップ」の思いを、サービスの形にして世に出すことを目指す。'
    },
    {
      id: 2,
      name: '美好 琢磨',
      imgUrl: '/img/img_member_miyoshi.svg',
      post: 'CEO',
      article:
        'ソシエテ・ジェネラル・アセット、マネックス・グループを経て<br>2013年　株式会社クレディセゾン入社<br><br>クレディセゾンでは、アセットマネージメント事業を統括し、「永久不滅ポイントでの投信ポイント運用」を発明してリリース。<br>2018年　STOCK POINT株式会社　取締役<br><br>社内ガバナンス含めた経営統括と、ポイントビジネスについての営業推進や様々な関係機関や株主等ステークホルダーとの調整、広報等担当'
    }
  ]
}

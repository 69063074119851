export function getDotDate(yymmdd_nn: string): string {
  const yyyy = `20${yymmdd_nn.slice(0, 2)}`
  const mm = yymmdd_nn.slice(2, 4).replace(/^0+/, '')
  const dd = yymmdd_nn.slice(4, 6).replace(/^0+/, '')
  return `${yyyy}.${mm}.${dd}`
}

export function getDayOfWeek(yymmdd: string): string {
  const dayOfWeekList = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  const date = new Date(
    2000 + Number(yymmdd.slice(0, 2)),
    Number(yymmdd.slice(2, 4)) - 1,
    Number(yymmdd.slice(4, 6))
  )
  return dayOfWeekList[date.getDay()]
}

export function getDateString(date: Date): string {
  if (!Number.isNaN(date.getTime())) {
    const yyyy = String(date.getFullYear())
    const mm = ('0' + (date.getMonth() + 1)).slice(-2)
    const dd = ('0' + date.getDate()).slice(-2)
    const hh = ('0' + date.getHours()).slice(-2)
    const mi = ('0' + date.getMinutes()).slice(-2)
    const ss = ('0' + date.getSeconds()).slice(-2)
    return `${yyyy}/${mm}/${dd} ${hh}:${mi}:${ss}`
  } else {
    return ''
  }
}

export function getServerDateString(): string {
  // ローカル日時を取得
  let serverDate = new Date()

  // クエリにdt日時がある場合はその日時を取得
  const query = location.search.slice(1)
  if (query) {
    query.split('&').forEach(function (queryStr) {
      const queryArr = queryStr.split('=')
      if (queryArr[0] == 'dt') {
        const queryDate = new Date(decodeURIComponent(queryArr[1]))
        if (!Number.isNaN(queryDate.getTime())) {
          serverDate = queryDate
        }
      }
    })
  }

  return getDateString(serverDate)
}

export function getDateFromYYMMDD_NN(yymmdd_nn: string): Date {
  const yyyy = `20${yymmdd_nn.slice(0, 2)}`
  const mm = yymmdd_nn.slice(2, 4).replace(/^0+/, '')
  const dd = yymmdd_nn.slice(4, 6).replace(/^0+/, '')
  return new Date(`${yyyy}/${mm}/${dd}`)
}

export function getYearStringFromYYMMDD_NN(yymmdd_nn: string): string {
  const yyyy = `20${yymmdd_nn.slice(0, 2)}`
  return yyyy
}

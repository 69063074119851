












































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class YearRadio extends Vue {
  @Prop() onChangeCheck!: (checkedYear: string) => void
  @Prop() yearList?: Array<string>

  checkedYear = ''

  changeCheck(year: string): void {
    if (year == this.checkedYear) {
      this.checkedYear = ''
      this.onChangeCheck(this.checkedYear)
    } else {
      this.onChangeCheck(year)
    }
  }
}

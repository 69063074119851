










































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import PressObj from '@/interfaces/PressObj'
import { getDotDate } from '@/lib/dateModules'
import { getCategoryClass } from '@/lib/categoryModules'

@Component
export default class NewsList extends Vue {
  @Prop() newsList?: Array<PressObj>

  getCategoryClass(category: string): string {
    return getCategoryClass(category)
  }

  getDotDate(yymmdd_nn: string): string {
    return getDotDate(yymmdd_nn)
  }
}








































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { Route } from 'vue-router'

@Component({
  components: {
    Header,
    Nav,
    Footer
  }
})
export default class App extends Vue {
  isNavOpen = false

  mounted(): void {
    this.createPageTitle(this.$route)
  }

  @Watch('$route')
  onUrlChange(): void {
    this.createPageTitle(this.$route)
  }

  toggleNavOpen(): void {
    this.isNavOpen = !this.isNavOpen
  }

  createPageTitle(to: Route): void {
    // headのtitle
    if (to.meta.title && to.meta.title !== 'Top') {
      var setTitle = to.meta.title
      document.title = `${setTitle} | STOCK POINT株式会社`
    } else {
      document.title = 'STOCK POINT株式会社 | ポイントから世界を動かす。'
    }

    // headのdescription
    if (to.meta.desc) {
      var setDesc = to.meta.desc
      document
        .querySelector("meta[name='description']")
        ?.setAttribute('content', setDesc)
    } else {
      document
        .querySelector("meta[name='description']")
        ?.setAttribute(
          'content',
          'StockPoint（ストックポイント）は、個別企業の株価に連動して価値の変わる、今までにないポイントのカタチ。現金も口座開設も不要、スマホアプリをダウンロードするだけ。擬似投資体験を始めよう。'
        )
    }
  }

  isUsingTemplate(): boolean {
    if (this.$route.name == 'Cocokabu') {
      return false
    } else {
      return true
    }
  }
}
